<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <Ffly4uAuthLogo />
              <DropdownTranslate />
              <h1>{{ $t('auth.passwordReset.title') }}</h1>
              <p>
                {{ $t('auth.passwordReset.description') }}
              </p>
              <v-form
                ref="form"
                v-model="validForm"
                v-on:submit.prevent="resetPassword"
              >
                <v-text-field
                  v-model="username"
                  :label="$t('auth.username')"
                  :rules="[rules.required, rules.username]"
                  required
                  backgroundColor="white"
                  solo
                  append-icon="mdi-account-outline">
                </v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('auth.password')"
                  :rules="[rules.required, rules.min, rules.password]"
                  required
                  v-on:click:append="passwordVisibility = !passwordVisibility"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('auth.confirmPassword')"
                  :rules="[rules.required, rules.confirmed]"
                  required
                  v-on:click:append="passwordVisibility = !passwordVisibility"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="code"
                  :label="$t('auth.passwordReset.code')"
                  :rules="[rules.required]"
                  required
                  backgroundColor="white"
                  solo
                  append-icon="mdi-fingerprint"
                ></v-text-field>
                <v-layout align-center justify-center row fill-height>
                  <v-btn
                    :disabled="!validForm"
                    type="submit"
                    class="white--text"
                    color="primary"
                    @click="validate()"
                  >
                      {{ $t('auth.passwordReset.button') }}
                  </v-btn>
                </v-layout>
                <v-layout align-center justify-center row fill-height class="my-4">
                  <router-link :to="{name: 'login'}">
                    {{ $t('auth.passwordReset.rememberingPassword') }}
                  </router-link>
                </v-layout>
                <div class="my-6">
                  <p class="error--text" v-if="error">{{ $t('auth.passwordReset.error') }}</p>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Ffly4uAuthLogo from '@/components/Ffly4uAuthLogo.vue';

export default {
  name: 'ResetPassword',
  components: { Ffly4uAuthLogo },
  data() {
    return {
      validForm: true,
      passwordVisibility: false,
      password: '',
      confirmPassword: '',
      error: false,
      username: decodeURIComponent(this.$route.query.username || ''),
      code: decodeURIComponent(this.$route.query.code || ''),
      rules: {
        required: (value) => !!value || this.$t('misc.required'),
        min: (v) => v.length >= 8 || this.$t('misc.min8Chars'),
        confirmed: (value) => value === this.password || this.$t('misc.differentPassword'),
        username: (v) => (v.length >= 1 && v.length <= 128) || this.$t('misc.invalidUsername'),
        password: (value) => {
          const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
          return pattern.test(value) || this.$t('misc.invalidPassword');
        },
      },
    };
  },
  methods: {
    /**
    * Ensure that form is valid before making backend request
    */
    validate() {
      this.error = false;
      if (this.$refs.form.validate()) {
        this.resetPassword();
      }
    },
    async resetPassword() {
      try {
        await this.$store
          .dispatch('auth/RESET_PASSWORD', {
            username: this.username,
            password: this.password,
            resetToken: this.code,
          });

        const data = {
          loading: false,
          title: this.$t('auth.passwordNew.success'),
          description: this.$t('auth.passwordNew.successDescription'),
          color: 'success',
          timeout: 5000,
        };
        this.$store.commit('snackbars/ADD_MESSAGE', data);
        this.$router.push({
          name: 'login',
          query: { username: this.username },
        });
      } catch (error) {
        this.error = error.message;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('auth.passwordReset.title'),
    };
  },
};
</script>
